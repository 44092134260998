import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { RxCaretDown } from "react-icons/rx";

import { logoIcon, logoText } from "../../assets/images";
import { PublicRoutes } from "../../types/Routes";
import { Dropdown } from "antd";
import { Links } from "../../enums/links.enum";

export const DesktopNav = (): ReactElement => {
  const aboutItems: {
    key: string;
    label: React.ReactNode;
    disabled?: boolean;
  }[] = [
    {
      key: "1",
      label: (
        <Link className="!text-brand-gray" to={PublicRoutes.ABOUT}>
          About Eazipower
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link className="!text-brand-gray" to={PublicRoutes.NEWS}>
          News
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link className="!text-brand-gray" to={PublicRoutes.TEAMS}>
          Team
        </Link>
      ),
    },
  ];

  const servicesItems: {
    key: string;
    label: React.ReactNode;
    disabled?: boolean;
  }[] = [
    {
      key: "4",
      label: (
        <Link className="!text-brand-gray" to={PublicRoutes.DONATIONS}>
          Donations
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          className="!text-brand-gray"
          to={PublicRoutes.BECOME_A_DISTRIBUTOR}
        >
          Become a Distributor
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link className="!text-brand-gray" to={PublicRoutes.TRACK_ORDER}>
          Track an Order
        </Link>
      ),
    },
  ];

  return (
    <div className="flex-row justify-between w-80% mx-auto hidden xl:flex py-4">
      <div className="flex flex-row items-center gap-3">
        <img src={logoIcon} alt="Eazipower Logo Icon" className="w-8" />
        <img src={logoText} alt="Eazipower Logo Text" className="w-32" />
      </div>
      <div className="flex items-center justify-around w-1/2 font-semibold leading-17.73px text-sm text-brand-gray">
        <Link to={PublicRoutes.HOME} className="px-5 cursor-pointer py-30px">
          Home
        </Link>
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          // open={false}
          menu={{
            items: aboutItems,
            className: "!p-[6px] w-[180px] !shadow-md !rounded-md !-mt-[20px]",
            onClick: () => {},
          }}
        >
          <span className="flex items-center h-20 px-5 cursor-pointer py-30px">
            <span>About</span>
            <RxCaretDown size="24px" />
          </span>
        </Dropdown>
        <Link to={PublicRoutes.SHOP} className="px-5 py-30px">
          Shop
        </Link>
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          menu={{
            items: servicesItems,
            className: "!p-[6px] w-[180px] !shadow-md !rounded-md !-mt-[20px]",
          }}
        >
          <span className="flex items-center h-20 px-5 cursor-pointer py-30px">
            <span>Services</span>
            <RxCaretDown size="24px" />
          </span>
        </Dropdown>
        <Link to={PublicRoutes.PRICING} className="px-5 py-30px">
          Pricing
        </Link>
        <Link to={PublicRoutes.CONTACT} className="px-5 py-30px">
          Contact
        </Link>
      </div>
      <div className="flex items-center">
        <div
          className="px-6 py-3 text-white rounded-lg cursor-pointer bg-brand-yellow text-13px"
          onClick={() => {
            window.open(Links.EAZIPOWER_APP);
          }}
        >
          Launch Application
        </div>
      </div>
    </div>
  );
};
