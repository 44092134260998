import { Toaster } from "sonner";
import { Layout } from "./components/layout/Layout";
import { AppProvider } from "./components/providers/AppProvider";

import { MdInfo } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import { ImSpinner } from "react-icons/im";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RegistrationContextProvider } from "./context/RegistrationContextProvider";

function App() {
  return (
    <Layout>
      <>
        <RegistrationContextProvider>
          <AppProvider />
        </RegistrationContextProvider>
        <Toaster
          duration={3000}
          position="bottom-left"
          closeButton
          icons={{
            success: <FaRegCircleCheck className="text-[25px] text-green" />,
            info: <MdInfo className="text-[25px] text-blue" />,
            warning: (
              <IoWarningOutline className="text-[25px] text-darkYellow" />
            ),
            error: <GiCancel className="text-[25px] text-red" />,
            loading: <ImSpinner className="text-[25px] text-black" />,
          }}
          toastOptions={{
            classNames: {
              icon: "w-[25px]",
            },
            className: "sonner",
          }}
        />
      </>
    </Layout>
  );
}

export default App;
