import { ReactElement, useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import Aos from "aos";
import "aos/dist/aos.css";

import { queryClient } from "../../utils/react-query";
import AppRouter from "../../routes/AppRouter";

export const AppProvider = (): ReactElement => {
  useEffect(() => {
    Aos.init({
      delay: 100,
    });
    Aos.refresh();
  }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </>
  );
};
