import {
  ChangeEvent,
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import { BusinessType } from "../enums/app.enum";
import { TDistributorRegistrationInput } from "../services/distributor/distributor.interface";

type TRegistrationContext = {
  step: 1 | 2 | 3 | 4;
  businessType: BusinessType.REGISTERED | BusinessType.INDIVIDUAL | undefined;
  formData: TDistributorRegistrationInput;
  updateFormData: (e: ChangeEvent<HTMLInputElement>, val?: string) => void;
  updateStep: (step: 1 | 2 | 3 | 4) => void;
  updateBusinessType: (
    type: BusinessType.INDIVIDUAL | BusinessType.REGISTERED,
  ) => void;
  resetFormData: () => void;
};

const RegistrationContext = createContext<TRegistrationContext | undefined>(
  undefined,
);

const initialFormData = {
  fullName: "",
  dob: "",
  email: "",
  phone: "",
  country: "",
  status: "",
  legalBusinessName: "",
  countryOfReg: "",
  regNo: "",
  regDoc: "",
  staffSize: "",
  noOfDevices: "",
  businessDescription: "",
  streetAddress: "",
  state: "",
  city: "",
  verificationOption: "",
  verificationFile: "",
};

export const RegistrationContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }): ReactElement => {
  const [formData, setFormData] =
    useState<TDistributorRegistrationInput>(initialFormData);
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [businessType, setBusinessType] = useState<
    BusinessType.INDIVIDUAL | BusinessType.REGISTERED | undefined
  >();

  const updateFormData = (e: ChangeEvent<HTMLInputElement>, val?: string) => {
    const name = e.target.name as keyof TDistributorRegistrationInput;
    const value = val ?? e.target.value;
    const newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const updateStep = (step: 1 | 2 | 3 | 4) => {
    setStep(step);
  };

  const updateBusinessType = (
    type: BusinessType.INDIVIDUAL | BusinessType.REGISTERED,
  ) => {
    setBusinessType(type);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  return (
    <RegistrationContext.Provider
      value={{
        formData,
        step,
        businessType,
        updateFormData,
        updateStep,
        updateBusinessType,
        resetFormData,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);

  if (!context) {
    throw Error();
  }

  return context;
};
