import { ReactElement, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuIcon } from "../Atoms/MenuIcon";
import { RxCaretDown } from "react-icons/rx";
import { PublicRoutes } from "../../types/Routes";
import { logoIcon, logoText } from "../../assets/images";

export const MobileNav = (): ReactElement => {
  const [isNavOpen, setIsNavOpen] = useState<Boolean | null>(null);
  const [showAboutSublinks, setShowAboutSublinks] = useState(false);
  const [showServicesSublinks, setShowServicesSublinks] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuListRef = useRef<HTMLDivElement | null>(null);

  const curPath = useLocation();

  const setActiveLink = (activePath: string) => {
    if (activePath === curPath.pathname) {
      return "text-brand-yellow";
    }
  };

  const handleNavClose = () => {
    setIsNavOpen(false);
    setShowAboutSublinks(false);
    setShowServicesSublinks(false);
  };

  const navDisplay =
    isNavOpen === true
      ? "flex animate-mobile-nav-open"
      : isNavOpen === false
      ? "flex animate-mobile-nav-close"
      : "hidden";

  useEffect(() => {
    function handleClickOutsideMenu(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        menuListRef.current &&
        !menuListRef.current.contains(event.target as Node)
      ) {
        if (isNavOpen === true) {
          setIsNavOpen(false);
        }
      }
    }

    document.addEventListener("click", handleClickOutsideMenu);

    return () => {
      document.removeEventListener("click", handleClickOutsideMenu);
    };
  }, [setIsNavOpen, isNavOpen]);

  return (
    <div className="relative block bg-white xl:hidden">
      <div
        className="absolute z-40 flex flex-row items-center justify-between w-full px-6 py-4 bg-white"
        ref={menuRef}
      >
        <div className="flex flex-row items-center gap-3">
          <img src={logoIcon} alt="Eazipower Logo Icon" className="w-8" />
          <img src={logoText} alt="Eazipower Logo Text" className="w-32" />
        </div>

        <div
          className="block lg:hidden"
          onClick={() => {
            setIsNavOpen(!isNavOpen);
            setShowAboutSublinks(false);
            setShowServicesSublinks(false);
          }}
        >
          <MenuIcon />
        </div>
      </div>
      <div
        ref={menuListRef}
        className={` 
       flex flex-col gap-5 px-5 text-sm 
      text-brand-gray leading-17.73px absolute z-30 
      bg-white w-full transition-all py-4 font-semibold ${navDisplay}`}
      >
        <Link
          to={PublicRoutes.HOME}
          className={`${setActiveLink(PublicRoutes.HOME)} transition-all hover:text-brand-yellow`}
          onClick={() => {
            handleNavClose();
          }}
        >
          Home
        </Link>
        <div>
          <div
            className="flex flex-row items-center justify-between text-brand-gray"
            onClick={() => {
              setShowAboutSublinks(!showAboutSublinks);
              setShowServicesSublinks(false);
            }}
          >
            <span className="hover:text-brand-yellow">About</span>
            <RxCaretDown size="24px" />
          </div>
          <div
            className={`flex flex-col justify-center w-11/12 gap-4 p-4 mx-auto mt-4 font-medium border rounded-md border-brand-border ${showAboutSublinks ? "flex" : "hidden"}`}
          >
            <Link
              className={`${setActiveLink(PublicRoutes.ABOUT)} block transition-all hover:text-brand-yellow`}
              to={PublicRoutes.ABOUT}
              onClick={() => {
                handleNavClose();
              }}
            >
              About Eazipower
            </Link>
            <Link
              className={`${setActiveLink(PublicRoutes.NEWS)} block transition-all hover:text-brand-yellow`}
              to={PublicRoutes.NEWS}
              onClick={() => {
                handleNavClose();
              }}
            >
              News
            </Link>
            <Link
              className={`${setActiveLink(PublicRoutes.TEAMS)} block transition-all hover:text-brand-yellow`}
              to={PublicRoutes.TEAMS}
              onClick={() => {
                handleNavClose();
              }}
            >
              Teams
            </Link>
          </div>
        </div>
        <Link
          className={`${setActiveLink(PublicRoutes.SHOP)} transition-all hover:text-brand-yellow`}
          to={PublicRoutes.SHOP}
          onClick={() => {
            handleNavClose();
          }}
        >
          Shop
        </Link>
        <div>
          <div
            className="flex flex-row items-center justify-between text-brand-gray"
            onClick={() => {
              setShowServicesSublinks(!showServicesSublinks);
              setShowAboutSublinks(false);
            }}
          >
            <span className="hover:text-brand-yellow">Services</span>
            <RxCaretDown size="24px" />
          </div>
          <div
            className={`flex flex-col justify-center w-11/12 gap-4 p-4 mx-auto mt-4 font-medium border rounded-md border-brand-border ${showServicesSublinks ? "flex" : "hidden"}`}
          >
            <Link
              className={`${setActiveLink(PublicRoutes.DONATIONS)} transition-all hover:text-brand-yellow`}
              to={PublicRoutes.DONATIONS}
              onClick={() => {
                handleNavClose();
              }}
            >
              Donations
            </Link>
            <Link
              className={`${setActiveLink(PublicRoutes.BECOME_A_DISTRIBUTOR)} transition-all hover:text-brand-yellow`}
              to={PublicRoutes.BECOME_A_DISTRIBUTOR}
              onClick={() => {
                handleNavClose();
              }}
            >
              Become a distributor
            </Link>
            <Link
              className={`${setActiveLink(PublicRoutes.TRACK_ORDER)} transition-all hover:text-brand-yellow`}
              to={PublicRoutes.TRACK_ORDER}
              onClick={() => {
                handleNavClose();
              }}
            >
              Track an order
            </Link>
          </div>
        </div>
        <Link
          className={`${setActiveLink(PublicRoutes.PRICING)} transition-all hover:text-brand-yellow`}
          to={PublicRoutes.PRICING}
          onClick={() => {
            handleNavClose();
          }}
        >
          Pricing
        </Link>
        <Link
          className={`${setActiveLink(PublicRoutes.CONTACT)} transition-all hover:text-brand-yellow`}
          to={PublicRoutes.CONTACT}
          onClick={() => {
            handleNavClose();
          }}
        >
          Contact
        </Link>
      </div>
      <div className="hidden lg:block">
        <button>Launch App</button>
      </div>
    </div>
  );
};
