import { ReactElement, Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";

import NotFound from "../pages/NotFound";
import { PublicRoutes } from "../types/Routes";
import Splash from "../components/layout/Splash";
import { DesktopNav } from "../components/layout/DesktopNav";
import { MobileNav } from "../components/layout/MobileNav";
import { Footer } from "../components/layout/Footer";

const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const News = lazy(() => import("../pages/News"));
const NewsDetail = lazy(() => import("../pages/NewsDetail"));
const Teams = lazy(() => import("../pages/Teams"));
const Shop = lazy(() => import("../pages/Shop"));
const ProductDetail = lazy(() => import("../pages/ProductDetail"));
const Checkout = lazy(() => import("../pages/Checkout"));
const SolarSolutions = lazy(() => import("../pages/SolarSolutions"));
const BecomeADistributor = lazy(() => import("../pages/BecomeADistributor"));
const TrackOrder = lazy(() => import("../pages/TrackOrder"));
const TrackDetails = lazy(() => import("../pages/TrackDetails"));
const Pricing = lazy(() => import("../pages/Pricing"));
const Contact = lazy(() => import("../pages/Contact"));
const Donations = lazy(() => import("../pages/Donations"));
const DistributorEmpowerment = lazy(
  () => import("../pages/DistributorEmpowerment"),
);

function WebRouter(): ReactElement {
  return (
    <Suspense fallback={<Splash />}>
      <DesktopNav />
      <MobileNav />
      <Outlet />
      <Footer />
    </Suspense>
  );
}

const AboutRouter = (): ReactElement => {
  return <Outlet />;
};

const ServicesRouter = (): ReactElement => {
  return <Outlet />;
};

export const publicRoutes = [
  {
    path: PublicRoutes.HOME,
    element: <WebRouter />,
    children: [
      { path: PublicRoutes.HOME, name: "Home", element: <Home /> },

      {
        path: PublicRoutes.ABOUT,
        elements: <AboutRouter />,
        children: [
          {
            path: PublicRoutes.ABOUT,
            name: "About",
            element: <About />,
          },
          {
            path: PublicRoutes.NEWS,
            name: "News",
            element: <News />,
          },
          {
            path: PublicRoutes.TEAMS,
            name: "Teams",
            element: <Teams />,
          },
        ],
      },

      { path: PublicRoutes.SHOP, name: "Shop", element: <Shop /> },

      {
        path: PublicRoutes.PRODUCT_DETAIL,
        name: "Product Detail",
        element: <ProductDetail />,
      },
      {
        path: PublicRoutes.CHECKOUT,
        name: "Checkout",
        element: <Checkout />,
      },

      {
        path: PublicRoutes.NEWS_DETAIL,
        name: "News Detail",
        element: <NewsDetail />,
      },

      {
        path: PublicRoutes.SERVICES,
        elements: <ServicesRouter />,
        children: [
          {
            path: PublicRoutes.SOLAR_SOLUTIONS,
            name: "Solar Solutions",
            element: <SolarSolutions />,
          },
          {
            path: PublicRoutes.DONATIONS,
            name: "Donations",
            element: <Donations />,
          },
          {
            path: PublicRoutes.BECOME_A_DISTRIBUTOR,
            name: "Become a Distributor",
            element: <BecomeADistributor />,
          },
          {
            path: PublicRoutes.TRACK_ORDER,
            name: "Track Order",
            element: <TrackOrder />,
          },
          {
            path: PublicRoutes.TRACK_DETAILS,
            name: "Track Details",
            element: <TrackDetails />,
          },
        ],
      },

      { path: PublicRoutes.PRICING, name: "Pricing", element: <Pricing /> },
      { path: PublicRoutes.CONTACT, name: "Contact", element: <Contact /> },
      {
        path: PublicRoutes.DISTRIBUTOREMPOWERMENT,
        name: "Distributor Empowerment",
        element: <DistributorEmpowerment />,
      },

      { path: PublicRoutes.NOT_FOUND, name: "404", element: <NotFound /> },
    ],
  },
];
