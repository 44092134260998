export enum PublicRoutes {
  HOME = "/",
  ABOUT = "/about",
  REGISTER = "/register",
  SERVICES = "/services",
  NEWS = "/about/news",
  NEWS_DETAIL = "/about/news/:newsId",
  TEAMS = "/about/teams",
  SHOP = "/shop",
  SOLAR_SOLUTIONS = "/services/solar-solutions",
  DONATIONS = "/services/donations",
  BECOME_A_DISTRIBUTOR = "/services/become-a-distributor",
  TRACK_ORDER = "/services/track-order",
  TRACK_DETAILS = "/services/track-order/:trackId",
  PRICING = "/pricing",
  PRODUCT_DETAIL = "/shop/product-details/:productId",
  CHECKOUT = "/shop/product-details/:productId/checkout",
  CONTACT = "/contact",
  NOT_FOUND = "*",
  DISTRIBUTOREMPOWERMENT = "/distributorempowerment",
}
