import { ReactElement } from "react";
import { RiInstagramFill } from "react-icons/ri";
import { MdOutlineFacebook } from "react-icons/md";
import { IoLogoLinkedin, IoLogoTwitter } from "react-icons/io";

import { logoFull } from "../../assets/images";

export const Footer = (): ReactElement => {
  return (
    <div className="bg-brand-yellow">
      <div className="flex flex-row flex-wrap justify-between px-3 py-10 text-white xl:w-80% xl:m-auto xl:pt-24 items-start">
        <div className="w-full xl:w-fit xl:flex-2">
          <img
            src={logoFull}
            alt="Eazipower logo full"
            className="h-60px xl:h-fit xl:w-150px"
          />
          <p className="text-sm font-light leading-19.1px mt-4 mb-7 xl:w-264px">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, et
            laudantium! Corporis incidunt.
          </p>
        </div>
        <div className="my-4 xl:flex-1 xl:mt-0">
          <p className="text-base font-bold leading-21.82px">Quick Links</p>
          <ul className="text-13px leading-17.73px font-semibold flex flex-col gap-3 mt-4">
            <li>Home</li>
            <li>Shop</li>
            <li>Donations</li>
            <li>Contact</li>
            <li>About</li>
          </ul>
        </div>
        <div className="my-4 xl:flex-1 xl:mt-0">
          <p className="text-base font-bold leading-21.82px">Contact Us</p>
          <ul className="text-13px leading-17.73px font-semibold flex flex-col gap-3 mt-4">
            <li>Address:</li>
            <li>Email:</li>
            <li>Mobile: +234 8107833512</li>
          </ul>
        </div>
        <div className="w-full my-4 xl:w-fit xl:flex-1 xl:mt-0">
          <p className="text-base font-bold leading-21.82px">Connect with us</p>
          <div className="flex flex-row gap-3 mt-4">
            <RiInstagramFill size="26.67px" className="cursor-pointer" />
            <MdOutlineFacebook size="26.67px" className="cursor-pointer" />
            <IoLogoLinkedin size="26.67px" className="cursor-pointer" />
            <IoLogoTwitter size="26.67px" className="cursor-pointer" />
          </div>
        </div>
      </div>
      <div className="px-3 py-4 capitalize bg-white text-13.06px xl:text-sm xl:leading-24.55px xl:px-0">
        <div className="xl:w-80% xl:mx-auto">
          &copy; Copyright 2024{" "}
          <span className="font-semibold text-brand-yellow">Eazipower</span> all
          rights reserved
        </div>
      </div>
    </div>
  );
};
