import { ReactElement } from "react";

export const MenuIcon = (): ReactElement => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer "
    >
      <path
        d="M4.25 25.5V22.6667H29.75V25.5H4.25ZM4.25 18.4167V15.5833H29.75V18.4167H4.25ZM4.25 11.3333V8.5H29.75V11.3333H4.25Z"
        fill="#6B7280"
      />
    </svg>
  );
};
