import { GraphQLErrorResponse } from "../types/graphql-error";

export const errorParser = (err: unknown): string => {
  const errorMessage = (err as { message: string }).message;

  console.log(err, "0000000------");

  // Extract the JSON part of the error message
  const jsonStartIndex = errorMessage.indexOf("{");
  const jsonPart = errorMessage.substring(jsonStartIndex);

  if (jsonPart === "TypeError: Failed to fetch") {
    return "An error occurred, please check your network!";
  }

  const parsedError = JSON.parse(jsonPart) as GraphQLErrorResponse;

  const errMsg =
    parsedError.response?.errors?.[0]?.message ?? "Something went wrong!";

  return errMsg || errorMessage;
};
